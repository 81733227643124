import type { FetchError } from "ofetch";
import { useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { requestLogin, requestDeactivate, HttpCodes, urls } from "@magnit/layer-api/api";
import { storage } from "~/utils/consts";
import Cookie, { MAX_AGE_YEAR } from "~/utils/cookie";
import { getJWTExpiration, type IJWT } from "~/utils/jwt";
import { Routes } from "~/utils/routes";
import getCookieDomain from "~/utils/getCookieDomain";

interface IAuthState {
  authorized: boolean;
  registered: boolean;
  tokenValue: null | string;
  tokenExpires: null | number;
}

interface IAuthStatus {
  login: "initial" | "pending" | "success" | "error";
}

export const useAuthStore = defineStore("auth", () => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const { cookieDomain: configCookieDomain } = useRuntimeConfig().public;
  const { error: showToastError } = useNotification();
  const { hide: hidePageLoader, show: showPageLoader } = usePageLoader();
  const { send } = useAnalytics();
  const { hostname } = useRequestURL();
  const loyaltyCache = useLocalStorage(storage.bonuses, "");
  const cookieDomain = String(configCookieDomain || getCookieDomain(hostname));
  const cookieJWT = useCookie<IJWT | null | undefined>(storage.jwt, {
    domain: cookieDomain,
    maxAge: MAX_AGE_YEAR,
    default: () => ({ access: undefined, refresh: undefined }),
    watch: true,
  });

  let refreshPromise: Promise<void> | null = null;

  // TODO tokenExpires и tokenValue заложены для будущего рефакторинга useTransport
  const state = reactive<IAuthState>({
    authorized: false,
    registered: false,
    tokenValue: null,
    tokenExpires: null,
  });

  const status = reactive<IAuthStatus>({
    login: "initial",
  });

  const getMagnitId = () => Cookie.get(storage.magnitIDCode) || "";
  const setMagnitId = (code: string) => {
    Cookie.set(storage.magnitIDCode, code, { "max-age": 3600 });
  };

  const fetchJWTAccessToken = async () => {
    const JWT = state.tokenValue || cookieJWT.value?.access;
    if (!JWT) return null;
    const expiration = state.tokenExpires || getJWTExpiration(JWT);
    if (expiration) {
      if (!isJWTExpired(expiration)) return JWT;
    }
    await refresh();
    return cookieJWT.value?.access;
  };

  const getRegister = () => state.registered;
  const setRegister = (value: boolean) => {
    state.registered = value;
  };

  const setTokens = (access: string, refresh: string) => {
    state.tokenValue = access;
    state.tokenExpires = getJWTExpiration(access);
    cookieJWT.value = { access, refresh };
    setAuth(true);
  };

  const setAuth = (value: boolean) => {
    state.authorized = value;
  };

  const clearCurrentTokenState = () => {
    state.tokenValue = null;
    state.tokenExpires = null;
  };

  async function logout() {
    status.login = "initial";

    setAuth(false);
    clearCurrentTokenState();

    cookieJWT.value = null;
    Cookie.delete(storage.ksid);

    loyaltyCache.value = "";

    await router.push(Routes.Login);
  }

  async function login() {
    showPageLoader();

    const aud = useRuntimeConfig().public.aud || "";
    const magnitIDCode = getMagnitId();

    const { data, error } = await requestLogin(aud, magnitIDCode);

    if (data.value) {
      setMagnitId("");
      setTokens(data.value.accessToken, data.value.refreshToken);

      status.login = "success";
      hidePageLoader();
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      if (error.value.data.code) {
        send("LoginModal:Validation:Error", {
          type: getRegister() ? "registration" : "login",
          error_type: error.value.data.code,
          text: error.value.data.message,
        });
      }

      await router.push(Routes.Login);

      status.login = "initial";
      hidePageLoader();
      showToastError();
    }
  }

  const checkLogin = async () => {
    if (cookieJWT.value?.access) {
      const expiration = state.tokenExpires;
      const isExpired = expiration ? isJWTExpired(expiration) : true;
      if (isExpired) {
        try {
          await refresh();
          return true;
        } catch {
          return false;
        }
      }
      state.tokenExpires = expiration;
      state.tokenValue = cookieJWT.value?.access;
      setAuth(true);
      return true;
    }
    return false;
  };

  async function refresh() {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        $fetch<{
          accessToken: string;
          refreshToken: string;
        }>(urls.auth.refresh, {
          baseURL: "/magnit-id",
          headers: getHeaders({}, config.public.version as string),
          method: "POST",
          body: {
            aud: "loyalty-web",
            refreshToken: cookieJWT.value?.refresh,
          },
        })
          .then(
            (data) => {
              if (data.accessToken) {
                setTokens(data.accessToken, data.refreshToken);
                resolve();
              }
            },
            async (error: FetchError) => {
              if (error?.statusCode) {
                if (error.statusCode === HttpCodes.Unauthorized) {
                  await logout();
                  return;
                }

                if (error.statusCode >= HttpCodes.Error4xx) {
                  status.login = "initial";
                  showToastError();
                  await logout();
                  reject(new Error("refresh request error"));
                }
              }
            },
          )
          .catch(async () => {
            await logout();
          });
      });
    }

    return refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }

  async function deactivate() {
    await refresh();
    const { data, error } = await requestDeactivate();

    if (data.value) {
      return data.value;
    } else {
      throw error.value;
    }
  }

  return {
    getMagnitId,
    setMagnitId,
    getRegister,
    setRegister,
    setAuth,
    login,
    logout,
    checkLogin,
    refresh,
    fetchJWTAccessToken,
    deactivate,
    state,
    status,
  };
});
