import type { FetchError } from "ofetch";
import { useRouter } from "vue-router";
import { ErrorCodes, HttpCodes } from "@magnit/layer-api/api";
import Cookie from "~/utils/cookie";
import { storage } from "~/utils/consts";
import { Routes } from "~/utils/routes";

const useResponseCheck = () => {
  const router = useRouter();

  const hasError = async (error: FetchError) => {
    if (
      error.statusCode === HttpCodes.UnprocessableEntity &&
      error.data?.code === ErrorCodes.IncompleteProfile
    ) {
      Cookie.set(storage.incompleteProfile, "1");
      await router.push(Routes.ProfileIncomplete);
      return true;
    }

    return false;
  };

  return {
    hasError,
  };
};

export default useResponseCheck;
