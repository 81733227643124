import { useRoute, useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { useAnalyticManager } from "@magnit/analytic-events";
import {
  requestActivate,
  requestBalance,
  requestCards,
  requestConfirmEmail,
  requestFavoriteCategoriesLegacy,
  requestFavoriteCategories,
  requestMergeCards,
  requestPermissions,
  requestPersonalPromotionDetail,
  requestPersonalPromotions,
  requestProfile,
  requestQr,
  requestRegister,
  requestUpdatePermissions,
  requestUpdateProfile,
  requestUpdFavoriteSectionCategories,
  requestUpdFavoriteCategoriesLegacy,
  ErrorCodes,
  HttpCodes,
} from "@magnit/layer-api/api";
import {
  type IPersonalPromotionsItem,
  type IUserCardsIdentifier,
  type IUserPermissions,
  type IUserProfile,
  type IUserFavoriteCategories,
  type IUserFavoriteSectionCategories,
  type IOffer,
  OfferStatus,
  PersonalPromotionOfferStatus,
  TickStatus,
  TickType,
} from "@magnit/layer-api/typings";
import { getFormattedYYYYMMDD } from "~/utils/dates";
import { LOYALTY_TYPE, storage } from "~/utils/consts";
import { routeParams, Routes } from "~/utils/routes";
import { MODALS } from "~/stores/modals";

interface IUserProgress {
  bonusPercentage?: number;
  currentBonusPercentage?: number;
  currentMoneyAmount?: number;
  currentProducts?: number;
  leftToBuyMoneyAmount?: number;
  leftToBuyProducts?: number;
  maxBonus: boolean;
  moneyAmount?: number;
  products?: number;
  status?: TickStatus;
  type?: TickType;
}

interface IUserSticker {
  totalStickerBalance: number;
  type: string;
}

export interface IUserBalance {
  bonuses: {
    totalPointBalance: number;
    totalExpressPoints: number;
  };
  stickers: {
    expirationDate: string | undefined;
    expirationStickerBalance: number | undefined;
  } & IUserSticker;
  progress: IUserProgress;
}

type IStoreStatus = "initial" | "pending" | "success" | "error";

interface IUserStatus {
  balance: IStoreStatus;
  balanceError: string | null;
  cardMerge: IStoreStatus;
  cardMergeError: string | null;
  cardsList: IStoreStatus;
  delete: IStoreStatus;
  emailConfirmation: IStoreStatus;
  profile: IStoreStatus;
  profileUpdate: IStoreStatus;
  qrcode: IStoreStatus;
  qrcodeError: string | null;
  register: IStoreStatus;
  permissions: IStoreStatus;
  permissionsUpdate: IStoreStatus;
  favoriteCategories: IStoreStatus;
  favoriteCategoriesUpdate: IStoreStatus;
  personalPromotions: IStoreStatus;
  personalPromotionDetail: IStoreStatus;
  personalPromotionActivation: IStoreStatus;
}
interface IMagnitIdValidationError {
  field: string;
  errCode: string;
}
export interface IMagnitIdRegistrationError {
  code: string;
  debugMessage: string;
  message: string;
  validationErrors: IMagnitIdValidationError[];
}

type IProfileKeys = keyof IUserProfile;
export type IPermissionsKeys = keyof IUserPermissions;

const QR_TIMEOUT = 5 * 60 * 1000;

export const useUserStore = defineStore("user", () => {
  const { error: showToastError } = useNotification();
  const { send } = useAnalytics();
  const toasts = useToasts();
  const { hasError } = useResponseCheck();
  const router = useRouter();
  const route = useRoute();
  const { globalParams } = useAnalyticManager();
  const modalsStore = useModalsStore();
  const useConfig = useRuntimeConfig();

  const savedQrCode = useLocalStorage<{
    code: string;
  }>(storage.qrcode, {
    code: "",
  });

  const savedLoyaltyBalance = useLocalStorage(storage.bonuses, "");

  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(storage.uuid, {
    uuid: "",
  });

  const profile = ref<IUserProfile>({
    userId: "",
    birthDate: undefined,
    email: "",
    isEmailConfirmed: false,
    firstName: "",
    phone: "",
    cardNumber: "",
  });

  const cards = ref<IUserCardsIdentifier[]>([]);

  const permissions = ref<IUserPermissions>({
    isEmailPermitted: false,
    isSMSPermitted: true,
    isDigitalReceiptPermitted: false,
    isPushPermitted: false,
  });

  const qrcode = ref("");

  const balance = ref<IUserBalance>();

  const personalPromotions = ref<IPersonalPromotionsItem[]>([]);
  const personalPromotionDetail = ref<IPersonalPromotionsItem>();

  const status = reactive<IUserStatus>({
    balance: "initial",
    balanceError: null,
    cardsList: "initial",
    cardMerge: "initial",
    cardMergeError: null,
    delete: "initial",
    emailConfirmation: "initial",
    profile: "initial",
    profileUpdate: "initial",
    qrcode: "initial",
    qrcodeError: null,
    register: "initial",
    permissions: "initial",
    permissionsUpdate: "initial",
    favoriteCategories: "initial",
    favoriteCategoriesUpdate: "initial",
    personalPromotions: "initial",
    personalPromotionDetail: "initial",
    personalPromotionActivation: "initial",
  });

  const favoriteCategories = ref<IUserFavoriteCategories>();
  const favoriteCategoriesSection = ref<IUserFavoriteSectionCategories>();
  const activeFavoriteCategories = computed(() =>
    favoriteCategories.value
      ? favoriteCategories.value?.offers.filter(
        (offer) => offer.offerStatus === OfferStatus.OptedIn,
      )
      : [],
  );
  const favoriteSectionCategoriesListPlain = computed(() => {
    if (!favoriteCategoriesSection.value) {
      return [] as IOffer[];
    }

    return favoriteCategoriesSection.value.sections.reduce<IOffer[]>((acc, item) => {
      const subItemAcc = item.subSections.reduce<IOffer[]>((subAcc, subItem) => {
        const offers = subItem.generalOffers.filter((offer) => offer.offerStatus !== OfferStatus.OptedIn);
        return [...subAcc, ...offers];
      }, []);
      return [...acc, ...subItemAcc];
    }, []);
  });
  const activeFavoriteSectionCategories = computed(() => {
    if (!favoriteCategoriesSection.value) {
      return [] as IOffer[];
    }

    return favoriteCategoriesSection.value.sections.reduce<IOffer[]>((acc, item) => {
      const subItemAcc = item.subSections.reduce<IOffer[]>((subAcc, subItem) => {
        const offers = subItem.generalOffers.filter((offer) => offer.offerStatus === OfferStatus.OptedIn);
        return [...subAcc, ...offers];
      }, []);
      return [...acc, ...subItemAcc];
    }, []);
  });
  const availableCountFavoriteCategories = computed(() =>
    favoriteCategoriesSection.value
      ? favoriteCategoriesSection.value.maxOptIn
      : 0,
  );
  const availableFavoriteCategories = computed(() =>
    favoriteCategories.value
      ? favoriteCategories.value.maxOptIn - activeFavoriteCategories.value.length
      : 0,
  );

  const availableFavoriteSections = computed(() =>
    favoriteCategoriesSection.value
      ? favoriteCategoriesSection.value.maxOptIn - activeFavoriteSectionCategories.value.length
      : 0,
  );

  const activeFavoriteCategoriesCompose = computed(() => {
    return useConfig.public.favoriteSectionEnabled
      ? activeFavoriteSectionCategories.value
      : activeFavoriteCategories.value;
  });

  const favoriteCategoriesCompose = computed(() => {
    return useConfig.public.favoriteSectionEnabled
      ? favoriteCategoriesSection.value
      : favoriteCategories.value;
  });

  const availableFavoriteCategoriesCompose = computed(() => {
    return useConfig.public.favoriteSectionEnabled
      ? availableFavoriteSections.value
      : availableFavoriteCategories.value;
  });

  watch(availableFavoriteCategoriesCompose, (next) => {
    if (!next) {
      modalsStore.close(MODALS.FavoriteCategories);
    }
  });

  watch(
    profile,
    (next) => {
      globalParams({
        magnit_id: next.userId || null,
      });
    },
    { immediate: true },
  );

  watch(
    savedUserId,
    (next) => {
      if (next.uuid) {
        profile.value.userId = savedUserId.value.uuid;
      }
    },
    { immediate: true },
  );

  function setSavedUUID(uuid: string) {
    savedUserId.value.uuid = uuid;
  }

  function clearSavedUUID() {
    savedUserId.value.uuid = "";
  }

  async function getFavoriteCategories() {
    status.favoriteCategories = "pending";

    const { data, error } = await requestFavoriteCategoriesLegacy();

    if (data.value) {
      favoriteCategories.value = data.value;

      status.favoriteCategories = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.favoriteCategories = "error";
      await hasError(error.value);
    }
  }

  async function getFavoriteCategoriesNew() {
    status.favoriteCategories = "pending";

    const { data, error } = await requestFavoriteCategories();

    if (data.value) {
      // :todo эта история в дальнейшем заменится в layer-api после переключениыя
      // @ts-ignore
      favoriteCategoriesSection.value = (data.value as unknown).currentPeriod;

      status.favoriteCategories = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.favoriteCategories = "error";
      await hasError(error.value);
    }
  }

  async function updateFavoriteCategories(codes: string[]) {
    status.favoriteCategoriesUpdate = "pending";
    let _errorsCategories: string[] = [];
    const { favoriteSectionEnabled } = useConfig.public;
    const { data, error } = favoriteSectionEnabled
      ? await requestUpdFavoriteSectionCategories(codes)
      : await requestUpdFavoriteCategoriesLegacy(codes);

    if (data.value !== null) {
      status.favoriteCategoriesUpdate = "success";
      toasts.success({
        text: codes.length > 1 ? "Категории выбраны" : "Категория выбрана",
      });
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      _errorsCategories = error.value?.data.errors.map(
        (e: { code: string; categoryCode: string; message: string; }) => e.categoryCode,
      );
      if (error.value?.data.code === ErrorCodes.UnprocessableEntity) {
        status.favoriteCategoriesUpdate = "success";
        toasts.warning({ text: "Не все категории были подтверждены" });
      } else if (error.value?.data.code === ErrorCodes.AlreadyAccepted) {
        status.favoriteCategoriesUpdate = "success";
        toasts.warning({ text: "Категории уже выбраны" });
      } else {
        status.favoriteCategoriesUpdate = "error";
        showToastError();
      }

      send("FC:Save:Error:View", {
        error_type: error.value?.data.code,
        catList: favoriteCategories.value?.offers
          .filter((i) => _errorsCategories.includes(i.code))
          .map((i) => i.name)
          .join(";"),
      });
    }

    if (status.favoriteCategoriesUpdate === "success") {
      send("FC:Save:Success:View", {
        selectedCatList: favoriteCategories.value?.offers
          .filter((i) => codes.includes(i.code) && !_errorsCategories.includes(i.code))
          .map((i) => i.name)
          .join(";"),
      });

      if (useConfig.public.favoriteSectionEnabled) {
        await getFavoriteCategoriesNew();
      } else {
        await getFavoriteCategories();
      }
      await router.push(Routes.ProfileFavoriteCategories);
    }
  }

  onMounted(() => {
    if (savedQrCode.value.code) {
      qrcode.value = savedQrCode.value.code;
    }
    // ToDo: временно отключили после проблемы с куками и т.д.
    // if (savedLoyaltyBalance.value) {
    //   try {
    //     balance.value = JSON.parse(savedLoyaltyBalance.value);
    //   } catch (e: any) {
    //     balance.value = undefined;
    //     savedLoyaltyBalance.value = "";
    //     throw createError({
    //       message: e?.message || "Ошибка парсинга объекта бонусов",
    //       fatal: false,
    //     });
    //   }
    // }
  });

  watch(qrcode, (next) => {
    if (next && next !== savedQrCode.value.code) {
      savedQrCode.value.code = next;
    }
  });

  async function register(
    patch: Pick<IUserProfile, "firstName" | "birthDate" | "email"> &
    Pick<IUserPermissions, "isSMSPermitted">,
  ) {
    const authStore = useAuthStore();

    status.register = "pending";

    profile.value = {
      ...profile.value,
      firstName: patch.firstName,
      birthDate: patch.birthDate,
      email: patch.email,
    };

    permissions.value.isSMSPermitted = patch.isSMSPermitted;

    const requestData = {
      magnitIDCode: String(authStore.getMagnitId()),
      birthDate: profile.value.birthDate
        ? getFormattedYYYYMMDD(profile.value.birthDate)
        : undefined,
      firstName: profile.value.firstName,
      email: profile.value.email || undefined,
    };

    if (!profile.value.birthDate) {
      delete requestData.birthDate;
    }

    if (!profile.value.email) {
      delete requestData.email;
    }

    const { data, error } = await requestRegister(requestData);

    if (data.value) {
      profile.value.userId = data.value.userId;

      send("RegistrationPage:Success");

      status.register = "success";

      await authStore.login();

      if (permissions.value.isSMSPermitted) {
        await updatePermissions(permissions.value.isSMSPermitted, "isSMSPermitted");
      }
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.register = "initial";
      throw error.value.data;
    }
  }

  async function getPersonalPromotions() {
    status.personalPromotions = "pending";
    const { data, error } = await requestPersonalPromotions({
      headers: {
        "X-Loyalty-Type": LOYALTY_TYPE,
      },
    });

    if (data.value) {
      personalPromotions.value = data.value.items.sort((promo) => {
        return promo.offerStatus === PersonalPromotionOfferStatus.InteractiveWithoutCoupon ? -1 : 1;
      });
      status.personalPromotions = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.personalPromotions = "error";
      await hasError(error.value);
    }
  }

  async function getPersonalPromotionDetail(code: string) {
    status.personalPromotionDetail = "pending";
    router.push({
      query: { promotion: code },
    });
    const { data, error } = await requestPersonalPromotionDetail(code, {
      headers: {
        "X-Loyalty-Type": LOYALTY_TYPE,
      },
    });

    if (data.value) {
      personalPromotionDetail.value = data.value;
      status.personalPromotionDetail = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.personalPromotionDetail = "error";
      router.push({
        query: { promotion: null },
      });
      await hasError(error.value);
    }
  }

  async function activatePersonalPromotion(code: string) {
    status.personalPromotionActivation = "pending";
    const { data, error } = await requestActivate(code);

    if (data.value) {
      personalPromotionDetail.value = data.value;
      status.personalPromotionActivation = "success";
      personalPromotions.value = [];
      status.personalPromotions = "initial";
      send("PersonalPromotionsPage:Detail:Activate:Success");
      toasts.success({ text: "Предложение активировано" });
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.personalPromotionActivation = "error";
      await hasError(error.value);
      toasts.warning({ text: "Не активировалось. Попробуйте ещё раз" });
    }
  }

  async function getProfile() {
    status.profile = "pending";

    const { data, error } = await requestProfile();

    if (data.value) {
      const { birthDate, ...etc } = data.value;

      setSavedUUID(data.value.userId);

      profile.value = {
        ...profile.value,
        ...etc,
        birthDate: birthDate ? new Date(birthDate) : undefined,
      };

      status.profile = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.profile = "error";
      await hasError(error.value);
    }
  }

  async function getPermissions() {
    status.permissions = "pending";

    const { data, error } = await requestPermissions();

    if (data.value) {
      permissions.value = data.value;
      status.permissions = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.permissions = "error";

      await hasError(error.value);
    }
  }

  async function updatePermissions(
    value: IUserPermissions[IPermissionsKeys],
    keyName: IPermissionsKeys,
  ) {
    status.permissionsUpdate = "pending";

    const patch: Partial<IUserPermissions> = {};
    patch[keyName] = value;

    const { data, error } = await requestUpdatePermissions(patch);

    if (data.value) {
      permissions.value = data.value;
      status.permissionsUpdate = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.permissionsUpdate = "error";

      await hasError(error.value);
    }
  }

  async function updateProfile(patch: Partial<Record<IProfileKeys, string>>) {
    let customError = "";

    status.profileUpdate = "pending";

    const { data, error } = await requestUpdateProfile(patch);

    if (data.value) {
      const { birthDate, ...etc } = data.value;

      profile.value = {
        ...profile.value,
        ...etc,
        birthDate: birthDate ? new Date(birthDate) : undefined,
      };

      status.profileUpdate = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      if (error.value?.data?.code === ErrorCodes.EmailForbidden) {
        customError = "Укажите личную почту";
      }
      status.profileUpdate = "error";

      await hasError(error.value);

      return customError;
    }
  }

  async function confirmEmail(email: string) {
    status.emailConfirmation = "pending";

    const { data, error } = await requestConfirmEmail(email);

    if (data.value !== null) {
      status.emailConfirmation = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.emailConfirmation = "error";
    }
  }

  async function getQR() {
    status.qrcode = "pending";
    status.qrcodeError = null;

    const { data, error } = await requestQr();

    if (data.value) {
      profile.value.cardNumber = data.value.identifier;
      qrcode.value = `E${data.value.identifier}T${data.value.totp}`;

      status.qrcode = "success";

      setInterval(async () => {
        const { data } = await requestQr();

        if (data.value) {
          profile.value.cardNumber = data.value.identifier;
          qrcode.value = `E${data.value.identifier}T${data.value.totp}`;

          status.qrcode = "success";
        }

        if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
          status.qrcode = "error";
          status.qrcodeError = error.value?.data?.code || null;
        }
      }, QR_TIMEOUT);
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.qrcode = "error";
      status.qrcodeError = error.value?.data?.code || null;

      await hasError(error.value);
    }
  }

  async function getBalance() {
    status.balance = "pending";
    status.balanceError = null;

    const { data, error } = await requestBalance();

    if (data.value) {
      balance.value = {
        bonuses: data.value.bonusBalance,
        progress: {
          maxBonus:
            data.value.benefits.ticks[data.value.benefits.ticks.length - 1].status ===
            TickStatus.done,
          currentBonusPercentage: data.value.benefits.currentBonusPercentage,
          ...data.value.benefits.ticks.find(({ status }) => status === TickStatus.inProgress),
        },
        stickers: {
          expirationDate: data.value.stickerBalance.expirationDate,
          expirationStickerBalance: data.value.stickerBalance.expirationStickerBalance,
          ...(data.value.stickerBalance.items.find(
            (item: IUserSticker) => item.type === "StickerRK2",
          ) || {
            totalStickerBalance: 0,
            type: "StickersRK2",
          }),
        },
      };

      try {
        savedLoyaltyBalance.value = JSON.stringify(balance.value);
      } catch (e: any) {
        savedLoyaltyBalance.value = "";
        throw createError({
          message: e?.message || "Ошибка сериализации объекта бонусов",
          fatal: false,
        });
      }

      status.balance = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.balance = "error";
      status.balanceError = error.value?.data.code || null;

      await hasError(error.value);
    }
  }

  async function getCards() {
    status.cardsList = "pending";

    const { data, error } = await requestCards();

    if (data.value) {
      cards.value = data.value.identifiers;
      status.cardsList = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.cardsList = "error";

      await hasError(error.value);
    }
  }

  async function mergeCard(patch: { cvc: string; identifier: string; token: string; }) {
    status.cardMerge = "pending";
    status.cardMergeError = null;

    const { data, error } = await requestMergeCards(patch.cvc, patch.identifier, patch.token);

    if (data.value !== null) {
      status.cardMerge = "success";
    }

    if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
      status.cardMerge = "error";
      status.cardMergeError = error.value.data.code || null;

      send("AddCardPage:Error:View", {
        error_type: error.value?.data.code,
        chapter: routeParams.firstAddCard in route.query ? "auth" : "sett",
      });

      await hasError(error.value);
    }
  }

  return {
    confirmEmail,
    getBalance,
    getCards,
    getProfile,
    setSavedUUID,
    clearSavedUUID,
    updateProfile,
    getPermissions,
    updatePermissions,
    getQR,
    mergeCard,
    register,
    getFavoriteCategories,
    getFavoriteCategoriesNew,
    updateFavoriteCategories,
    getPersonalPromotions,
    getPersonalPromotionDetail,
    activatePersonalPromotion,
    profile,
    cards,
    permissions,
    balance,
    qrcode,
    status,
    favoriteCategories,
    favoriteCategoriesSection,
    favoriteSectionCategoriesListPlain,
    activeFavoriteSectionCategories,
    availableCountFavoriteCategories,
    availableFavoriteSections,
    availableFavoriteCategories,
    activeFavoriteCategories,
    personalPromotions,
    personalPromotionDetail,
    activeFavoriteCategoriesCompose,
    favoriteCategoriesCompose,
    availableFavoriteCategoriesCompose,
  };
});
