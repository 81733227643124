import { HttpCodes } from "@magnit/layer-api/api";
import { ExternalRoutes, RedirectedRoutes, Routes } from "~/utils/routes";
import { storage } from "~/utils/consts";

export default defineNuxtRouteMiddleware(async (to, _) => {
  const useAuth = useAuthStore();
  const magnitId = useCookie<string>(storage.magnitIDCode);
  const incompleteProfile = useCookie<string>(storage.incompleteProfile);

  if (
    to.name === String(HttpCodes.NotFound) ||
    to.path === Routes.Landing ||
    to.path === Routes.Chat ||
    to.path === Routes.PersonalPromotionsMock ||
    to.path === Routes.CookiePolicy ||
    to.path === Routes.Subscription ||
    to.path === Routes.ProfileDelete
  ) {
    return;
  }

  if (!(await useAuth.checkLogin())) {
    if (magnitId.value && to.path === Routes.Registration) {
      return;
    }

    if (to.path.includes(Routes.PaymentMethods)) {
      return navigateTo({
        path: Routes.Login,
        query: { redirect: Routes.PaymentMethodsPay },
      });
    }

    if (to.path !== Routes.Login) {
      return navigateTo(Routes.Login);
    }
  } else {
    if (incompleteProfile.value && to.path !== Routes.ProfileIncomplete) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.Login || to.path === Routes.Registration) {
      return navigateTo(Routes.Main);
    }
  }

  /**
   * Редиректы со старых адресов
   */

  if (
    [
      RedirectedRoutes.App,
      RedirectedRoutes.FullEnrollment,
      RedirectedRoutes.Promo,
      RedirectedRoutes.Dashboard,
      RedirectedRoutes.Enrollment,
    ]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.Main, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.Terms].map((r) => r.replace("/", "")).includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(ExternalRoutes.MagnitPromopokarte, {
      external: true,
    });
  }
  if (
    [RedirectedRoutes.Offers].map((r) => r.replace("/", "")).includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.ProfilePersonalPromotions, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.MemberProfile]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(`${Routes.Profile}#tab=1`, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.Transactions]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.ProfileTransactions, { redirectCode: 301 });
  }
});
