import { useLocalStorage } from "@vueuse/core";
import { requestExperiments } from "@magnit/layer-api/api";
import type { IAbExperiments } from "@magnit/layer-api/typings";

type IStoreStatus = "initial" | "pending" | "success" | "error";

interface IAbStatus {
  experiments: IStoreStatus;
}

export const useAbStore = defineStore("ab", () => {
  const userStore = useUserStore();

  const status = reactive<IAbStatus>({
    experiments: "initial",
  });
  const experiments = ref<IAbExperiments | null>(null);

  async function getExperiments() {
    status.experiments = "pending";

    const savedUserId = useLocalStorage<{
      uuid: string;
    }>(storage.uuid, {
      uuid: "",
    });

    const splitId = savedUserId.value.uuid || userStore.profile.userId;
    const namespace = "myMagnit_web";

    const { data, error } = await requestExperiments(splitId, namespace);

    if (data.value) {
      experiments.value = data.value;
      status.experiments = "success";
    } else if (error.value) {
      status.experiments = "error";
    }
  }

  return {
    getExperiments,
    experiments,
    status,
  };
});
